import { object, string, number, ref } from "yup";
import {
  ForgotPassword,
  ConfirmOTPForgotPassword,
} from "../../../common/constants/api";
import config from "../../../../data/SiteConfig";
import AskMobileNo from "./components/AskMobileNo";
import OtpPage from "../../../components/OtpPage/OtpPage";
import ConfirmPassword from "../../../components/CreatePassword/CreatePassword";

export default [
  {
    id: "username",
    component: AskMobileNo,
    initialValues: {
      email: "",
      phone: "",
      err: false,
    },
    validationSchema: object().shape({
      phone: number("numbers only")
        .typeError("That doesn't look like a phone number")
        .positive("A phone number can't start with a minus")
        .integer("A phone number can't include a decimal point")
        .min(10, "must be a 10 digit no")
        .required("A phone number is required"),
    }),
    onAction: async (sectionValues, formValues) => {
      try {
        let body = {
          username: sectionValues.phone,
          userType: 1,
        };
        await ForgotPassword(body);
      } catch (err) {
        throw new Error(
          err?.response?.data?.message || "Oops! something went wrong!"
        );
      }
    },
  },
  {
    id: "otp",
    component: OtpPage,
    initialValues: {
      mobileOtp: "",
    },
    validationSchema: object().shape({
      mobileOtp: number()
        .typeError("Numbers only")
        .positive("A phone number can't start with a minus")
        .integer("A phone number can't include a decimal point")
        .min(4, "must be a 4 digit no")
        .required("An otp is required"),
    }),
    onAction: async (sectionValues, formValues) => {
      try {
        let body = {
          username: formValues.username.phone,
          otp: sectionValues.mobileOtp,
          userType: 1,
        };
        let tokenCredentials = await ConfirmOTPForgotPassword(body);
        config.forgotPassTempToken = tokenCredentials;
      } catch (err) {
        throw new Error(
          err.response.data.message || "Oops! something went wrong !!"
        );
      }
    },
  },
  {
    id: "confirmPassword",
    component: ConfirmPassword,
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: object().shape({
      password: string()
        .min(8, "Password must be at least 8 characters")
        .max(15, "Password must not be longer than 15 characters")
        .required("Password is required")
        .matches(
          /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
          "Password must contain at least 8 characters, one uppercase, one number and one special case character"
        ),
      confirmPassword: string()
        .min(8, "Please confirm your password")
        .required("Must match the password above ")
        .oneOf([ref("password"), null], "Password doesn't match"),
    }),
  },
];
