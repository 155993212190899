export const showHidePassword = (className = "showHide") => {
  var x = document.getElementsByClassName(className);
  if (x[0].type === "password") {
    x[0].type = "text";
    x[0].type = "text";
  } else {
    x[0].type = "password";
    x[0].type = "password";
  }
};
