import React, { useEffect, Fragment, useState } from "react";
import steps from "./steps";
import OtpPage from "../../../components/OtpPage/OtpPage";
import { navigate } from "gatsby";
import FormikWizard from "formik-wizard";
import { isLoggedIn } from "../../../common/utils/cookie-utils";
import AskMobileNo from "./components/AskMobileNo";
import {
  createPassword as createPasswordApi,
  login,
  getUserProfile,
} from "../../../common/constants/api";
import { setAuthCookies as setToken } from "../../../common/utils/cookie-utils";
import config from "../../../../data/SiteConfig";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import loginPagePic from "../../../images/icons/newLogin/loginPage.png";
import mainPagePic from "../../../images/icons/newLogin/mainPage.png";
import anotherImage from "../../../images/icons/newLogin/anotherImage.png";
import editIcon from "../../../images/icons/newLogin/edit.svg";
import BottomModal from "../../../common/components/bottomLoginModal/BottomModal";
import CommonSlide from "../../../common/components/CommonImageSlide/CommonSlide";
import useWindowResize from "../../../hooks/useWindowResize";
import { setAcl } from "../../../common/utils/acl-utils";
import { setRole, setTeam } from "../../../common/utils/auth-utils";

function FormWrapper({
  children,
  canGoBack,
  values,
  goToPreviousStep,
  wizard,
}) {
  const handleGoBack = () => {
    if (!canGoBack) return;
    let currStepIndex = wizard.steps.indexOf(wizard.step);
    wizard.push(steps[currStepIndex - 1].id);
  };
  const [windowWidth, windowHeight] = useWindowResize();
  const images = [mainPagePic, loginPagePic, anotherImage];
  const title = [
    "Become a Certified Business Partner (BP)",
    "Create Lead & upload Documents in System",
    "Earn money on Booking",
  ];

  return (
    <div className="w-full flex flex-row h-screen  overflow-hidden relative">
      <div className="w-full h-screen bg-orange-1400 md:bg-white">
        <div className="flex justify-start lg:mt-12 mt-6 px-4 w-20">
          <KeyboardBackspaceIcon
            className="block mt-3 md:mt-0 cursor-pointer "
            onClick={() =>
              canGoBack ? handleGoBack() : window.location.reload()
            }
            style={{ fontSize: 36 }}
          />
        </div>
        <div
          className={` font-bold text-base xxs:text-xl md:text-xl mt-0 md:mb-0 mx-auto text-center max-w-full pb-1`}
          style={{
            color: "#394759",
            font: "Open Sans",
          }}
        >
          {wizard.step.id === "otp" ? "Verify OTP" : "Re-set Password"}
        </div>
        {wizard.step.id !== "username" && (
          <div
            className=" font-bold text-base xxs:text-xl md:text-xl md:mb-2 lg:pb-8 pb-4 mx-auto text-center max-w-full"
            style={{
              color: "#1F3C3D",
              font: "Open Sans",
            }}
          >
            {values?.username?.phone ? (
              <Fragment>
                <span className="mr-1">{"+" + values?.username?.dialCode}</span>
                <span>{values?.username?.phone}</span>
                <img
                  src={editIcon}
                  className="inline-block ml-1 mb-1 cursor-pointer"
                  onClick={handleGoBack}
                />
              </Fragment>
            ) : (
              <Fragment>
                <span>**********</span>
                <img
                  src={editIcon}
                  className="inline-block ml-1 h-4 mb-3 cursor-pointer"
                  onClick={handleGoBack}
                />
              </Fragment>
            )}
          </div>
        )}
        {wizard.step.id === "username" && (
          <div
            className=" font-bold text-base xxs:text-xl md:text-xl md:mb-2 lg:pb-8 pb-0 mx-auto text-center max-w-full"
            style={{
              color: "#1F3C3D",
              font: "Open Sans",
            }}
          >
            Business Partner (BP/CP)
          </div>
        )}
        <BottomModal
          isLogin
          className="relative flex flex-row items-center w-full justify-center space-x-3 bg-white rounded-4xl rounded-b-none overflow-auto"
          style={{
            height:
              windowWidth < 767
                ? "calc(100% - 135px)"
                : wizard?.step?.id !== "confirmPassword"
                ? "calc(100% - 250px)"
                : "calc(100% - 100px)",
          }}
        >
          <div
            className={`max-w-full mx-auto h-full w-full overflow-auto ${
              wizard?.step?.id === "confirmPassword"
                ? "lg:max-w-md lg:px-2"
                : ""
            }`}
          >
            {wizard.step.id == "otp" ? (
              <OtpPage
                wizard={wizard}
                values={values}
                previousStep={goToPreviousStep}
                user={"bp"}
              />
            ) : wizard.step.id == "username" ? (
              <AskMobileNo wizard={wizard} values={values} user={"bp"} />
            ) : (
              children
            )}
          </div>
        </BottomModal>
      </div>
      <CommonSlide
        outerClass="lg:block hidden w-full h-screen"
        imageClass="h-screen relative"
        images={images}
        title={title}
      />
    </div>
  );
}
const index = props => {
  const handleSubmit = React.useCallback(async values => {
    await createPassword(values?.confirmPassword, values?.username);
  }, []);
  let createPassword = async (sectionValues, formValues) => {
    if (sectionValues?.password) {
      try {
        await createPasswordApi(
          sectionValues?.password,
          config?.forgotPassTempToken?.token
        );
        try {
          let res = await login({
            username: formValues?.phone,
            userType: 1,
            password: sectionValues?.password,
          });
          let [id, token, username, userType] = [
            res.id,
            res.token,
            res.username,
            res.userType,
          ];
          await setToken({ id, token, username, userType });
          setAcl(res?.acl);
          setRole(res?.role);
          setTeam(res?.team);
          navigate("/app/dashboard");
        } catch (err) {
          throw new Error(
            err.response?.data?.message || "Oops! something went wrong!"
          );
        }
      } catch (err) {
        throw new Error(
          err.response?.data?.message || "Oops! something went wrong!"
        );
      }
    }
  };
  useEffect(() => {
    isLoggedIn() ? navigate("/app/dashboard") : null;
  });
  return (
    <FormikWizard steps={steps} onSubmit={handleSubmit} render={FormWrapper} />
  );
};
export default index;
