import React, { useContext, useEffect } from "react";
import steps from "./steps";
import { navigate } from "gatsby";
import FormikWizard from "formik-wizard";
import { isLoggedIn } from "../../common/utils/cookie-utils";
import loginPagePic from "../../images/icons/newLogin/loginPage.png";
import mainPagePic from "../../images/icons/newLogin/mainPage.png";
import anotherImage from "../../images/icons/newLogin/anotherImage.png";
import useWindowResize from "../../hooks/useWindowResize";
import {
  changePassword,
  createPasswordv1 as createPasswordApi,
  createPasswordv1,
  login,
  updatePasswordAPIv1,
} from "../../common/constants/api";
import { setAuthCookies as setToken } from "../../common/utils/cookie-utils";
import config from "../../../data/SiteConfig";
import { setAcl } from "../../common/utils/acl-utils";
import {
  setIsApproved,
  setProjectHead,
  setRole,
  setTeam,
} from "../../common/utils/auth-utils";
import EnterMobNo from "../BP/BPOnBoarding/components/EnterMobNo";
import GlobalOtp from "../../common/components/GlobalOtpComp/GlobalOtpComp";
import { DeveloperDataContext } from "../../common/utils/appContext";
import { setUserDetailsInfo } from "../../common/utils/userDetailsInfo";

function FormWrapper({
  children,
  canGoBack,
  values,
  goToPreviousStep,
  wizard,
}) {
  const handleGoBack = () => {
    if (!canGoBack) return;
    let currStepIndex = wizard.steps.indexOf(wizard.step);
    wizard.push(steps[currStepIndex - 1].id);
  };
  const [windowWidth, windowHeight] = useWindowResize();
  const images = [mainPagePic, loginPagePic, anotherImage];
  const title = [
    "Become a Certified Business Partner (BP)",
    "Create Lead & upload Documents in System",
    "Earn money on Booking",
  ];
  return (
    <div className="w-full flex flex-col overflow-hidden relative">
      <div className="w-full">
        <div
          className={` font-bold text-base xxs:text-xl md:text-xl mt-0 md:mb-0 mx-auto text-left md:px-11 w-full max-h-full pb-1`}
          style={{
            color: "#394759",
          }}
        >
          {wizard.step.id === "otp" ? (
            "Verify OTP"
          ) : (
            <p className="headerColor md:px-0 px-6">
              Set New <span>Password</span>
            </p>
          )}
        </div>
        <div
          className={`max-w-full mx-auto h-full w-full overflow-auto ${
            wizard?.step?.id === "confirmPassword" ? "lg:max-w-md lg:px-2" : ""
          }`}
        >
          {wizard.step.id == "otp" ? (
            <GlobalOtp lizard={wizard} pageType={"forgotPassword"} />
          ) : wizard.step.id == "username" ? (
            <EnterMobNo lizard={wizard} pageType={"forgotPassword"} />
          ) : (
            children
          )}
        </div>
        {/* </BottomModal> */}
      </div>
    </div>
  );
}
const index = props => {
  const handleSubmit = React.useCallback(async values => {
    await createPassword(values?.confirmPassword, values?.username);
  }, []);
  let context = useContext(DeveloperDataContext);
  let createPassword = async (sectionValues, formValues) => {
    if (sectionValues?.password) {
      try {
        await createPasswordv1(
          {
            password: sectionValues?.password,
            deviceType: localStorage.getItem("deviceId"),
          },
          config?.forgotPassTempToken?.token
        );
        try {
          let res = await login({
            phoneNumber: formValues?.mobile,
            password: sectionValues?.password,
          });
          let [id, username, name, userType, token, team, role, acl, checks] = [
            res.id,
            res.username,
            res.name,
            res.userType,
            res.token,
            res.team,
            res.role,
            res.acl,
            res.checks,
          ];
          await setToken({ id, token, username, userType });
          setAcl(acl);
          setRole(role);
          let obj = {
            username: username,
            name: name,
            checks: checks,
          };
          setUserDetailsInfo(obj);
          setTeam(team);
          setProjectHead(checks?.isProjectHead);
          setIsApproved(checks?.isApproved);
          localStorage.setItem("loggedInUserName", name);
          userType == 1
            ? navigate("/app/dashboard/bp")
            : userType == 3
            ? navigate("/app/Consumer/Profile")
            : navigate("/app/dashboard");
        } catch (err) {
          throw new Error(err.response?.data?.message || "some error occurred");
        }
      } catch (err) {
        throw new Error(err.response?.data?.message || "some error occurred");
      }
    }
  };
  useEffect(() => {
    isLoggedIn() ? navigate("/app/opportunity") : null;
  });
  return (
    <FormikWizard steps={steps} onSubmit={handleSubmit} render={FormWrapper} />
  );
};
export default index;
