import React, { useState, useEffect, Fragment } from "react";
import { useFormikWizard } from "formik-wizard";
import { useFormikContext } from "formik";
import Error from "../../common/components/Error/Error";
import "./OtpPage.css";
import { ForgotPassword } from "../../common/constants/api";
import { Append_0 } from "../../common/constants/Append_0";
import useWindowResize from "../../hooks/useWindowResize";
import NewSpinner from "../../common/components/NewSpinner/NewSpinner";
import { ThrowError } from "../../common/constants/ThrowError";
import CommonOtpInput from "../../common/components/CommonOtpInput/CommonOtpInput";
import ErrorAltIcon from "../../images/icons/errorAltIcon.svg";
import withErrorHandlerComponent from "../../hoc/withErrorHandler/withErrorHandlerComponent";

const otpPage = (wizard, formValue, previousStep, user) => {
  const [error, setError] = useState("");
  const [phoneOtp, setPhoneOtp] = useState("");
  const [emailOtp, setemailOtp] = useState("");
  const [timeLeftToResentOtp, setTimeLeftToResentOtp] = useState(10);
  const [loading, setLoading] = useState(null);
  const [canResend, setCanResend] = useState(true);
  const [timeLeft, setTimeLeft] = useState(60);
  const {
    errors,
    touched,
    values,
    handleBlur,
    handleChange,
    setFieldValue,
  } = useFormikContext();
  const { status, values: wizardValues } = useFormikWizard();
  //allowing users to write type only numbers
  const otpResend = async type => {
    let data = {
      username:
        wizard?.values?.username?.email || wizard?.values?.username?.phone,
      userType:
        type === "MOBILE"
          ? wizard?.user == "dev" || formValue == "DEV"
            ? 2
            : 1
          : 0,
    };
    type === "MOBILE"
      ? setPhoneOtp("OTP has been sent to mobile")
      : setemailOtp("OTP has been sent to email");
    try {
      ForgotPassword(data)
        .then(res => {
          setLoading(false);
          setTimeLeftToResentOtp(10);
        })
        .catch(err => {
          // throw new Error(err.response.data);
          setLoading(false);
          setError(err.response.data);
          //return err.response.data;
        });
    } catch (err) {
      setError(err.response.data);
      //throw new Error(err.response.data);
    }

    let setOtpText = setTimeout(() => {
      setPhoneOtp("");
      setemailOtp("");
    }, 30000);
  };
  function validate(evt) {
    var theEvent = evt || window.event;

    // Handle paste
    if (theEvent.type === "paste") {
      key = event.clipboardData.getData("text/plain");
    } else {
      // Handle key press
      var key = theEvent.keyCode || theEvent.which;
      key = String.fromCharCode(key);
    }
    var regex = /[0-9]|\./;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }

  const countDown = () => {
    if (timeLeft > 0) {
      setTimeLeft(timeLeft => Append_0(timeLeft - 1, 2));
    }
  };
  useEffect(() => {
    let x = setInterval(() => countDown(), 1000);
    if (timeLeft === 0) clearInterval(x);
    return () => clearInterval(x);
  }, [timeLeft]);

  useEffect(() => {
    let y;
    if (loading !== null && !loading && timeLeftToResentOtp > -1) {
      if (timeLeftToResentOtp > 0)
        y = setInterval(() => {
          if (timeLeftToResentOtp > -1)
            setTimeLeftToResentOtp(timeLeftToResentOtp =>
              Append_0(timeLeftToResentOtp - 1, 2)
            );
        }, 1000);

      if (parseInt(timeLeftToResentOtp) === 0) {
        clearInterval(y);
        setCanResend(true);
      } else setCanResend(false);
    }
    return () => clearInterval(y);
  }, [loading, timeLeftToResentOtp]);

  const [windowWidth, windowHeight] = useWindowResize();

  return (
    <div
      className={
        (wizard?.values?.signup == undefined ? "" : "") +
        "h-full max-w-sm lg:max-w-md mx-auto text-auto flex flex-col justify-between pb-16 lg:pb-1 overflow-auto lg:overflow-none"
      }
    >
      <div className={wizard?.user === "rm" ? `hidden` : `mt-5 py-2 mx-auto`}>
        {wizard?.user === "rm" ? null : (
          <React.Fragment>
            <div className="mx-auto">
              {wizard?.user !== "rm" ? (
                <Fragment>
                  {timeLeft > 0 && (
                    <p className="text-black-850 md:text-grey-2232 font-roboto font-normal text-center ">
                      OTP Expires in :
                      <span className="font-roboto font-medium text-xl text-orange-1200 md:text-orange-1100">
                        {` 00:${timeLeft}`}
                      </span>
                    </p>
                  )}
                </Fragment>
              ) : (
                <Fragment>
                  <div className="flex flex-col space-y-2 md:space-y-10">
                    <h3 className="text-sm text-center  mx-auto">
                      Enter OTP sent to &nbsp; ******
                      {wizard?.values?.signup !== undefined
                        ? wizard?.values?.signup?.mobile
                            ?.toString()
                            .substring(6)
                        : wizard?.values?.username?.phone
                            ?.toString()
                            .substring(6)}
                      <button
                        onClick={wizard?.previousStep}
                        // onClick={() => props.submitHandler(1)}
                        className="mx-auto text-xs text-orange-500 mt-5 ml-5 text-center text-green-500"
                      >
                        Change Number
                      </button>
                    </h3>

                    {timeLeft > 0 && (
                      <p className="text-black-850 md:text-grey-2232 font-roboto font-normal text-center ">
                        OTP Expires in :
                        <span className="font-roboto font-medium text-xl text-orange-1200 md:text-orange-1100">
                          {` 00:${timeLeft}`}
                        </span>
                      </p>
                    )}
                  </div>
                </Fragment>
              )}
              <div className="my-1 mt-5 mx-auto text-center">
                <div
                  className="text-center mx-auto mt-10 text-black-500"
                  style={{ width: "fit-content" }}
                >
                  <p>Enter the OTP you received</p>
                  <CommonOtpInput
                    name="mobileOtp"
                    length={4}
                    inputStyle={{
                      ...(windowWidth < 767
                        ? { background: "white" }
                        : {
                            background: "#dce9f5",
                          }),
                      ...(windowWidth < 767
                        ? { border: "1px solid #dce9f5" }
                        : { border: "1px solid white" }),
                    }}
                    className="otpContainer"
                    inputClassName={`otpInput text-black-700 font-light md:font-normal`}
                    isNumberInput
                    autoFocus
                    onChangeOTP={otp => {
                      setPhoneOtp(otp);
                      setFieldValue("mobileOtp", otp, true);
                      setError("");
                    }}
                    onChange={handleChange}
                    onKeyPress={e => {
                      validate(e);
                    }}
                    onBlur={handleBlur}
                  />
                </div>
              </div>

              <div
                className={
                  (loading || !canResend
                    ? "text-grey-300 cursor-not-allowed "
                    : "text-orange-1200 md:text-orange-1100 ") +
                  " relative  text-xs text-center font-roboto font-medium mx-auto mt-5 cursor-pointer "
                }
                style={{
                  width: "fit-content",
                }}
                onClick={() => {
                  if (canResend) {
                    setLoading(true);
                    otpResend("MOBILE");
                    setTimeLeft(60);
                    setError("");
                  }
                }}
              >
                <p>
                  Re-send OTP to
                  {[wizard?.values?.username?.phone].map(
                    s =>
                      s.slice(0, 2) +
                      s.slice(2, 8).replace(/\d{5}$/, "*****") +
                      s.slice(8)
                  )}
                  {!canResend ? (
                    <span className="text-black-200 ml-1 ">
                      in
                      <span className="text-black-0 font-bold ml-1 mr-1">
                        {Append_0(timeLeftToResentOtp, 2)}
                      </span>
                      sec
                    </span>
                  ) : (
                    ""
                  )}
                </p>
                <span className="absolute top-0 inset-x-3/10">
                  {loading && <NewSpinner />}
                </span>
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
      {wizard?.user === "rm" ? (
        <>
          <div>
            <div className="mx-auto mt-10">
              {/* <h3 className="text-sm text-center  mx-auto">
                Enter OTP
                <button
                  onClick={wizard.previousStep}
                  // onClick={() => props.submitHandler(1)}
                  className="mx-auto text-xs text-orange-500 mt-0 ml-5 text-center text-green-500"
                >
                  Change Number
                </button>
              </h3> */}
              <div className="my-1 mt-5 mx-auto text-center">
                <div
                  className="text-center mx-auto mt-10 text-black-500"
                  style={{ width: "fit-content" }}
                >
                  <p>Enter the OTP you received</p>
                  <CommonOtpInput
                    name="emailOtp"
                    length={4}
                    inputStyle={{
                      ...(windowWidth < 767
                        ? { background: "white" }
                        : {
                            background: "#dce9f5",
                          }),
                      ...(windowWidth < 767
                        ? { border: "1px solid #dce9f5" }
                        : { border: "1px solid white" }),
                    }}
                    className="otpContainer"
                    inputClassName={`otpInput text-black-700 font-light md:font-normal`}
                    isNumberInput
                    autoFocus
                    onChangeOTP={otp => {
                      setPhoneOtp(otp);
                      setFieldValue("emailOtp", otp, true);
                      setError("");
                    }}
                    onChange={handleChange}
                    onKeyPress={e => {
                      validate(e);
                    }}
                    onBlur={handleBlur}
                  />
                </div>
              </div>
              <div className="relative left-auto max-w-sm px-4">
                <Error touched={touched.emailOtp} message={errors.emailOtp} />
              </div>
            </div>

            <div className="mx-auto text-center underline">
              <a
                className="capitalize text-sm my-1 text-center mx-auto text-green-500"
                href="javascript:void(0)"
                onClick={() => otpResend("EMAIL")}
              >
                {emailOtp == "" ? "Resend OTP" : ""}
              </a>
            </div>
            <div className="text-xs text-green-500 no-underline text-center">
              {emailOtp == "" ? "" : emailOtp}
            </div>
          </div>
        </>
      ) : null}
      <div>
        <div className="text-red-200 mt-4 px-6">{error?.message}</div>
        {status?.message && (
          <div className="px-2 lg:px-11 md:px-6">
            <div
              className="w-11/12 md:w-full flex space-x-2 md:mx-0 mx-4 p-3 px-4 items-center"
              style={{
                backgroundColor: "#FAEDE9",
              }}
            >
              <img src={ErrorAltIcon} alt="oops!" />
              <div className="text-black-400 text-sm">
                {withErrorHandlerComponent(status?.message)}
              </div>
            </div>
          </div>
        )}
        <div className="lg:px-2 px-6 pb-1 lg:pb-5 w-full mx-auto">
          <button
            id="submit"
            type="submit"
            disabled={
              wizard.values?.signup !== undefined
                ? values.mobileOtp.length < 4
                : wizard?.user == "bp" || wizard.user == "dev"
                ? values.mobileOtp.length < 4
                : values?.emailOtp?.length < 4
            }
            className="submitButton  w-full lg:w-10/12"
          >
            {wizard.values?.signup !== undefined ? "Register" : "Verify"}
          </button>
        </div>
      </div>
    </div>
  );
};
export default otpPage;
