import React, { useState } from "react";
import { useFormikContext } from "formik";
import Error from "../../../../common/components/Error/Error";
import { useFormikWizard } from "formik-wizard";
import PhoneInput from "react-phone-input-2";
import parsePhoneNumber from "libphonenumber-js";
import "react-phone-input-2/lib/material.css";
import useWindowResize from "../../../../hooks/useWindowResize";
import BottomModal from "../../../../common/components/bottomLoginModal/BottomModal";
import ErrorAltIcon from "../../../../images/icons/errorAltIcon.svg";
import withErrorHandlerComponent from "../../../../hoc/withErrorHandler/withErrorHandlerComponent";

const RegisterForm = props => {
  const [mobile_register, setRegister] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [checked, setChecked] = useState(false);
  const inputLength = mobileNumber.length;
  const firstDigitModified =
    mobileNumber.length > 0 && /[6-9]|\./.test(mobileNumber[0]);

  const handleCheckedChange = e => {
    setChecked(e.target.checked);
  };

  const {
    errors,
    touched,
    values,
    handleBlur,
    handleChange,
    // setFieldValue,
    isValid,
    dirty,
    initialValues,
    isSubmitting,
    setFieldValue,
    setFieldError,
    setFieldTouched,
  } = useFormikContext();
  const { values: wizardValues, setStatus, status, user } = useFormikWizard();
  const [windowWidth, windowHeight] = useWindowResize();

  return (
    <div
      className={`flex flex-col h-full justify-between w-full lg:max-w-md lg:px-11 pb-12 lg:pb-4 text-center mx-auto text-left mt-0 `}
    >
      <React.Fragment>
        <div className="w-full mx-auto pt-6">
          <div className="w-full mx-auto lg:px-0 px-4 mt-6">
            <PhoneInput
              country={"in"}
              value={"+91" + values?.phone}
              specialLabel={"Phone number"}
              countryCodeEditable={false}
              disableDropdown
              inputProps={{
                name: "phone",
                required: true,
                autoFocus: false,
                style: {
                  width: "100%",
                  height: "50px",
                  border: "1px solid #DCE9F5",
                  "&:hover": {
                    border: "1px solid #00ACB8",
                  },
                  "&:focus": {
                    border: "1px solid #00ACB8",
                    boxShadow: "0 0 0 1px #00acb8",
                  },
                },
              }}
              onlyCountries={["in", "ae", "us", "ca"]}
              onChange={(rawValue, countryData, event, formattedValue) => {
                let phoneNumber = parsePhoneNumber(
                  formattedValue,
                  countryData.countryCode
                );
                if (phoneNumber) {
                  setFieldValue("phone", phoneNumber.nationalNumber);
                  setFieldValue("dialCode", countryData.dialCode);
                  setFieldValue("countryCode", countryData.countryCode);
                  setMobileNumber(formattedValue);
                }
              }}
              onBlur={(e, countryData) => {
                let phoneNumber = parsePhoneNumber(
                  mobileNumber,
                  countryData.countryCode
                );
                if (phoneNumber) {
                  if (!phoneNumber.isValid()) {
                    setFieldError("phone", "Please enter correct phone number");
                  }
                }
              }}
              error={touched?.phone && errors?.phone}
              errorMsg={errors?.phone}
              touched={touched?.phone}
            />
          </div>
          <div className="my-1">
            <p
              style={{
                color: "#8993A3",
                fontFamily: "Open Sans",
                fontSize: "17px",
              }}
            >
              We will use your mobile number to send OTP
            </p>
          </div>
          {status?.message && (
            <div
              className="w-11/12 flex space-x-2 md:mx-0 mx-4 md:w-full p-3 px-4 items-center mt-2"
              style={{
                backgroundColor: "#FAEDE9",
              }}
            >
              <img src={ErrorAltIcon} alt="oops!" />
              <div className="text-black-400 text-sm">
                {withErrorHandlerComponent(status?.message)}
              </div>
            </div>
          )}
        </div>
        {/* checking the first Digit the input may be modified later */}
        {firstDigitModified ? (
          <div>
            <p className=" text-red-100 text-sm font-roboto">
              First digit cant be {values.phone[0]}
            </p>
          </div>
        ) : null}
        {/* checking the first Digit the input */}
        <div className="text-left px-4">
          <Error touched={touched.phone} message={errors.phone} />
        </div>
      </React.Fragment>

      <div className="lg:max-w-md w-full lg:px-0 px-6 pb-5 lg:pb-2">
        <button
          className="submitButton capitalize w-full"
          type="submit"
          disabled={
            isSubmitting || mobileNumber.length < 10 || firstDigitModified
          }
          onClick={() => setFieldValue("phone", values?.phone)}
        >
          Request OTP
        </button>
      </div>
    </div>
  );
};

export default RegisterForm;
