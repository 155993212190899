import React, { Fragment, useState, useEffect, useContext } from "react";
import { login, getAllDeviceType } from "../../common/constants/api";
import { navigate } from "gatsby";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Error from "../../common/components/Error/Error";
import Eye from "../../images/icons/eye (1).svg";
import CloseEye from "../../images/icons/slashEye.svg";
import { showHidePassword } from "../../common/components/ShowHidePassword/ShowHidePassword";
import {
  setToken as loginTokenSetter,
  setRole,
  setTeam,
  setProjectHead,
  setIsApproved,
  setAskForPassword,
} from "../../common/utils/auth-utils";
import { setAcl } from "../../common/utils/acl-utils";
import ForgotPasswordBP from "../../containers/ForgetPasswordFlow/MobileFlow/index";
import ForgotPasswordRM from "../../containers/ForgetPasswordFlow/index";
import ForgotPasswordDev from "../../containers/Developer/MobileFlow/index";
import PhoneInput from "react-phone-input-2";
import parsePhoneNumber from "libphonenumber-js";
import "react-phone-input-2/lib/material.css";
import { formInput } from "./SignIn.module.css";
import useWindowResize from "../../hooks/useWindowResize";
import BottomModal from "../../common/components/bottomLoginModal/BottomModal";
import { UserDeviceType } from "../../common/constants/UserDeviceDetails";
import ErrorAltIcon from "../../images/icons/errorAltIcon.svg";
import withErrorHandlerComponent from "../../hoc/withErrorHandler/withErrorHandlerComponent";
import { GA_EVENTS, gtagTrackEvent } from "../../common/constants/GA4";
import { setUserType } from "../../common/utils/cookie-utils";
import { DeveloperDataContext } from "../../common/utils/appContext";
import { setUserDetailsInfo } from "../../common/utils/userDetailsInfo";

const validationSchemaPhone = Yup.object().shape({
  phone: Yup.number("numbers only")
    .typeError("That doesn't look like a phone number")
    .positive("A phone number can't start with a minus")
    .integer("A phone number can't include a decimal point")
    .min(10, "must be a 10 digit no")
    .required("A phone number is required"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .max(15, "Password must not be longer than 15 characters")
    .required("Password is required")
    .matches(/[!@#$%^&*()\-_=+{};:,<.>]/g, "Must contain an Special Charecter"),
});
const validationSchemaEmail = Yup.object().shape({
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .max(15, "Password must not be longer than 15 characters")
    .required("Password is required")
    .matches(/[!@#$%^&*()\-_=+{};:,<.>]/g, "Must contain an Special Charecter"),
  email: Yup.string()
    .max(100, "Email address is Too Long!")
    .required("Please Enter your email ID")
    .matches(
      /^([a-z 0-9\.-_]+)@([a-z 0-9-]+).([a-z]{2,8})(.[a-z]{2,8})?$/,
      "invalid email"
    ),
});
const SignIn = props => {
  const [mobileNumber, setMobileNumber] = useState("");
  const inputLength = mobileNumber.length;
  const [showEyeIcon, setShowEyeIcon] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [email, setEmail] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [showForgotPassword, setForgotPassword] = useState(props?.showFP);
  const [windowWidth] = useWindowResize();
  const [deviceType, setDeviceType] = useState("");
  const [os, setOS] = useState("");
  const [allDevices, setAllDevices] = useState([]);
  const [deviceId, setDeviceId] = useState(localStorage.getItem("deviceId"));
  const [device, setDevice] = useState(2);
  const [statusCode, setStatusCode] = useState("");
  const [otpLogin, setOtpLogin] = useState();

  useEffect(() => {
    setDeviceType(UserDeviceType());

    getAllDeviceType()
      .then(res => setAllDevices(res))
      .catch(err => {});
  }, []);

  useEffect(() => {
    if (
      (deviceType == "iPhone" ||
        deviceType == "iPad" ||
        deviceType == "iPod") &&
      deviceId == 1
    ) {
      setOS("Ios");
    } else if (deviceType == "Android" && deviceId == 1) {
      setOS("Android");
    } else if (deviceType == "macOS") {
      setOS("Osx");
    } else {
      setOS("Windows");
    }
  }, [deviceType, allDevices, deviceId]);

  useEffect(() => {
    allDevices.map(each => {
      if (each?.name == os) {
        setDevice(each?.id);
      }
    });
  }, [os, allDevices]);

  const { appData, setAppData } = useContext(DeveloperDataContext);

  const genericLogin = async values => {
    let loginBody = {};
    if (otpLogin) {
      loginBody = {
        phoneNumber: values.phone,
        password: values.password,
        otpLogin: otpLogin,
        deviceType: device,
      };
    } else {
      loginBody = {
        phoneNumber: values.phone,
        password: values.password,
        deviceType: device,
      };
    }

    login(loginBody)
      .then(
        async ({
          id,
          username,
          name,
          userType,
          token,
          team,
          role,
          acl,
          checks,
        }) => {
          await loginTokenSetter({ id, token, username, userType });
          setAcl(acl);
          setRole(role);
          setTeam(team);
          setProjectHead(checks?.isProjectHead);
          setIsApproved(checks?.isApproved);
          setAskForPassword(checks?.askForPassword);
          setUserType(userType);
          let obj = {
            username: username,
            name: name,
            checks: checks,
          };
          setUserDetailsInfo(obj);
          localStorage.setItem("loggedInUserName", name);
          setDisabled(false);
          if (userType == 1) {
            gtagTrackEvent(GA_EVENTS.LOGIN_CLICK_BP);
            navigate("/app/dashboard/bp");
          } else if (userType == 3) {
            navigate("/app/Consumer/Properties");
          } else {
            gtagTrackEvent(GA_EVENTS.LOGIN_CLICK_RM);
            {
              windowWidth < 727
                ? navigate(`/app/attendance/bp/${0}`)
                : navigate("/app/dashboard");
            }
          }
        }
      )
      .catch(err => {
        if (err.response !== undefined) {
          setErrMsg(err.response.data.message);
          setStatusCode(err.response.code);
        }
        setDisabled(false);
      });
  };
  const [active, setActive] = React.useState(false);
  const [activate, setActivate] = React.useState(false);

  function handleActivation(e) {
    setActive(!!e.target.value);
  }

  function handleActivate(e) {
    setActivate(!!e.target.value);
  }

  //checking the first input of the phone no if someone modifies it later
  const firstDigitModified =
    mobileNumber.length > 0 && /[6-9]|\./.test(mobileNumber[0]);
  const handleCheckedChange = e => {
    setChecked(e.target.checked);
  };
  return (
    <Formik
      initialValues={{ dialcode: "in", phone: "", password: "" }}
      validationSchema={validationSchemaPhone}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        //login api call
        setDisabled(true);
        values.username = values.phone;
        await genericLogin(values, 2);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setFieldError,
        setFieldTouched,
      }) => (
        <>
          <div className="h-full" style={{ width: "100%" }}>
            {!props?.showFP ? (
              <BottomModal
                isLogin
                className={`h-screen  overflow-auto lg:overflow-hidden ${
                  !props?.showFP ? `rounded-4xl rounded-b-none` : ``
                }`}
                style={{
                  height: !props?.showFP
                    ? windowWidth < 767
                      ? ""
                      : props?.loginFrom === "RM"
                      ? "calc(100% - 180px)"
                      : "calc(100% - 200px)"
                    : "",
                }}
              >
                <>
                  <Form
                    autoComplete="off"
                    className={` ${
                      windowWidth < 767 && `bg-white`
                    } flex flex-col md:justify-between justify-start md:space-y-0 space-y-4 pb-5 lg:mt-5 w-full lg:max-w-md mx-auto text-left lg:px-11 px-2  overflow-auto`}
                    style={{ width: "100%" }}
                    onSubmit={handleSubmit}
                  >
                    <div className="">
                      {
                        <React.Fragment>
                          <div className="w-full flex mx-auto">
                            <div className="w-full mx-auto md:px-0 px-4 lg:mt-6 mt-8">
                              <PhoneInput
                                country={"in"}
                                value={"+91 " + values?.phone}
                                specialLabel={"Phone number"}
                                countryCodeEditable={false}
                                inputProps={{
                                  name: "phone",
                                  required: true,
                                  autoFocus: false,
                                  style: {
                                    width: "100%",
                                    height: "52px",
                                    border: "1px solid #DCE9F5",
                                    "&:hover": {
                                      border: "1px solid #00ACB8",
                                    },
                                    "&:focus": {
                                      border: "1px solid #00ACB8",
                                      boxShadow: "0 0 0 1px #00acb8",
                                    },
                                  },
                                }}
                                onlyCountries={[
                                  "in",
                                  "ae",
                                  "us",
                                  "ca",
                                  "gb",
                                  "sa",
                                  "qa",
                                  "sg",
                                  "hk",
                                  "my",
                                  "de",
                                  "it",
                                  "jp",
                                  "kw",
                                  "mv",
                                  "np",
                                  "lk",
                                  "th",
                                  "cn",
                                  "br",
                                  "bt",
                                  "au",
                                ]}
                                enableSearch
                                searchStyle={{
                                  width: "100%",
                                  maxWidth: "220px",
                                  height: "40px",
                                  "&:hover": {
                                    border: "1px solid #00ACB8",
                                  },
                                  "&:focus": {
                                    border: "1px solid #00ACB8",
                                  },
                                }}
                                dropdownStyle={{
                                  width: "250px",
                                  maxWidth: "250px",
                                }}
                                onChange={(
                                  rawValue,
                                  countryData,
                                  event,
                                  formattedValue
                                ) => {
                                  let phoneNumber = parsePhoneNumber(
                                    formattedValue,
                                    countryData.countryCode
                                  );
                                  if (phoneNumber) {
                                    setFieldValue(
                                      "phone",
                                      phoneNumber.nationalNumber
                                    );
                                    // setFieldValue("dialCode", countryData.dialCode);
                                    // setFieldValue("countryCode", countryData.countryCode);
                                    setMobileNumber(formattedValue);
                                  }
                                }}
                                error={touched?.phone && errors?.phone}
                                errorMsg={errors?.phone}
                                touched={touched?.phone}
                                onBlur={(e, countryData) => {
                                  let phoneNumber = parsePhoneNumber(
                                    mobileNumber,
                                    countryData.countryCode
                                  );
                                  if (phoneNumber) {
                                    if (!phoneNumber.isValid()) {
                                      setFieldError(
                                        "mobile",
                                        "mobile",
                                        "Please enter correct phone number"
                                      );
                                    }
                                  }
                                }}
                              />
                            </div>
                          </div>
                          {/* checking the first Digit the input */}
                          {firstDigitModified ? (
                            <div>
                              <p className="px-4 text-red-100 text-sm font-roboto">
                                First digit cant be {values.phone[0]}
                              </p>
                            </div>
                          ) : null}
                          <div className="px-4">
                            <Error
                              touched={touched.phone}
                              message={errors.phone}
                            />
                          </div>
                        </React.Fragment>
                      }
                      {
                        <label className="block mt-6 relative md:px-0 px-4">
                          <span
                            className="absolute bg-white ml-7 px-1 block text-xs capitalize"
                            style={{ marginTop: -4 }}
                          >
                            Password
                          </span>
                          <div className="">
                            <div className="outer-div flex w-full ">
                              <input
                                className={`showHideDes h-12 flex-1 placeholder-grey-placeholder rounded-sm mt-1 block w-full ${formInput}`}
                                type="password"
                                id="password"
                                name="password"
                                placeholder="**********"
                                onChange={e => {
                                  handleChange(e);
                                }}
                                onBlur={e => {
                                  handleBlur(e);
                                }}
                                value={values.password}
                                required
                                minLength="8"
                                maxLength="15"
                              />

                              {/* showHidePassword */}
                              <img
                                className="absolute right-0 mt-3 h-8 w-10 pr-4 mx-3"
                                onClick={() => {
                                  showHidePassword("showHideDes");
                                  setShowEyeIcon(!showEyeIcon);
                                }}
                                id="toggle"
                                src={showEyeIcon ? CloseEye : Eye}
                                alt="showPassword"
                              />
                            </div>
                          </div>
                        </label>
                      }
                      <div className="px-4">
                        <Error
                          touched={touched.password}
                          message={errors.password}
                        />
                      </div>
                      {errMsg && (
                        <div className="md:px-2 px-0">
                          <div
                            className="w-11/12 flex space-x-2 md:mx-0 mx-4 md:w-full p-3 px-4 items-center mt-4"
                            style={{
                              backgroundColor: "#FAEDE9",
                            }}
                          >
                            <img src={ErrorAltIcon} alt="oops!" />
                            <div className="text-black-400 text-sm">
                              {withErrorHandlerComponent(errMsg, statusCode)}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="w-full text-right pt-4 md:px-0 px-4 text-grey-300">
                      <span
                        className="font-bold cursor-pointer text-blue-900"
                        onClick={() => {
                          props?.lizard.next();
                        }}
                      >
                        Login
                      </span>{" "}
                      with OTP
                    </div>
                    <div className="lg:max-w-md w-full lg:px-0 md:pb-0 pb-10">
                      <button
                        id="submit"
                        className="submitButton newButton lg:w-full w-11/12 h-13"
                        type="submit"
                        disabled={
                          disabled ||
                          mobileNumber.length < 10 ||
                          values?.phone[0] == 0 ||
                          errors?.password ||
                          errors?.phone ||
                          firstDigitModified
                        }
                      >
                        Login
                      </button>

                      <div
                        className={` md:pt-2 pt-8 text-black-400 lg:mt-6 flex flex-col md:space-y-0 space-y-2 justify-start font-semibold cursor-pointer`}
                        onClick={async () => {
                          props?.setShowFP(true);
                          props?.setMoveNext("ForgotPassword");
                        }}
                      >
                        <p className="md:px-0 px-4">Forgot your password ?</p>
                        <p className="md:px-0 px-4 text-grey-250 font-normal">
                          That’s okay, it happens!
                          <span className="text-blue-900 font-normal ml-1">
                            Click here
                          </span>
                        </p>
                      </div>
                      {windowWidth < 767 ? (
                        props?.loginFrom === "RM" ? (
                          <div className="">
                            <button
                              className="submitButton newSignUp w-11/12 h-13"
                              type="submit"
                              onClick={() => navigate("/bp")}
                            >
                              Sign Up as Business Partner
                            </button>
                          </div>
                        ) : (
                          <button
                            className="submitButton newSignUp w-11/12 h-13"
                            type="submit"
                            onClick={() => navigate("/consumer")}
                          >
                            New to Fincity Sign Up
                          </button>
                        )
                      ) : (
                        " "
                      )}
                    </div>
                  </Form>
                </>
              </BottomModal>
            ) : props.loginFrom == "RM" ? (
              <ForgotPasswordRM />
            ) : props.loginFrom == "DEV" ? (
              <ForgotPasswordDev />
            ) : (
              <ForgotPasswordRM />
            )}
          </div>
        </>
      )}
    </Formik>
  );
};

export default SignIn;
