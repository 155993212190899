import { object, string, number, ref } from "yup";
import {
  ConfirmOTPForgotPasswordv1,
  ForgotPasswordv1,
} from "../../common/constants/api";
import config from "../../../data/SiteConfig";
import ConfirmPassword from "../../components/CreatePassword/CreatePassword";
import createPassword from "../../components/CreatePassword/CreatePassword";
export default [
  {
    id: "username",
    initialValues: {
      mobile: "",
      dialCode: "",
      countryCode: "",
      resMsg: "",
    },
    onAction: (sectionValues, formValues) => {
      try {
        let body = {
          phoneNumber: sectionValues?.mobile,
        };
        ForgotPasswordv1(body).then(res => {
          sectionValues.resMsg = res;
        });
      } catch (err) {
        throw new Error(
          err?.response?.data?.message || "Oops! something went wrong !!"
        );
      }
    },
  },
  {
    id: "otp",
    initialValues: {
      mobileOtp: "",
    },
    validationSchema: object().shape({
      mobileOtp: number()
        .typeError("Numbers only")
        .positive("A phone number can't start with a minus")
        .integer("A phone number can't include a decimal point")
        .min(4, "must be a 4 digit no")
        .required("An otp is required"),
    }),
    onAction: async (sectionValues, formValues) => {
      try {
        let body = {
          phoneNumber: formValues?.username?.mobile,
          otpLogin: { otpLogin: true, otp: sectionValues?.mobileOtp },
          deviceType: sectionValues?.device,
        };
        let tokenCredentials = await ConfirmOTPForgotPasswordv1(body);
        config.forgotPassTempToken = tokenCredentials;
      } catch (err) {
        throw new Error(err?.response?.data?.message);
      }
    },
  },
  {
    id: "confirmPassword",
    component: createPassword,
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: object().shape({
      password: string()
        .min(8, "Password must be at least 8 characters")
        .max(15, "Password must not be longer than 15 characters")
        .required("Password is required")
        .matches(
          /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
          "Password must contain at least 8 characters, one uppercase, one number and one special case character"
        ),
      confirmPassword: string()
        .min(8, "Please confirm your password")
        .required("Must match the password above ")
        .oneOf([ref("password"), null], "Passwords must match"),
    }),
  },
];
