import React from "react";
import { useFormikWizard } from "formik-wizard";
export const ThrowError = ({ ErrMsg }) => {
  const { status } = useFormikWizard() || {};
  return status?.message || ErrMsg ? (
    <p className="font-roboto font-normal text-base text-red-100 w-full break-words text-center mt-5">
      {status?.message || ErrMsg}
    </p>
  ) : null;
};
