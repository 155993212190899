import { object, string, number } from "yup";
import OtpPage from "../../components/OtpPage/OtpPage";
import SignIn from "../../components/SignIn/SignIn";

export default [
  {
    id: "SignIn",
    component: SignIn,
    initialValues: {
      mobile: "",
      email: "",
      password: "",
    },
    validationSchema: object().shape({
      mobile: string()
        .required("Required")
        .max(10)
        .matches(/([6-9]\d{9})/, "Mobile should be 10 digit number"),
      email: string()
        .email("Please enter email address")
        .max(100, "Email address is Too Long!")
        .required("Required")
        .matches(
          /^([a-z 0-9\.-_]+)@([a-z 0-9-]+).([a-z]{2,8})(.[a-z]{2,8})?$/,
          "invalid type"
        ),
      password: string()
        .min(8, "Password must be at least 8 characters")
        .max(15, "Password must not be longer than 15 characters")
        .required("Password is required")
        .matches(
          /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
          "Password must contain special character"
        ),
    }),
    onAction: async (sectionValues, formValues) => {},
  },
];
